
import { defineComponent } from 'vue'
import {
    getTeamPeople,
    TeamPeopleDto,
    addTeamPeople,
    editTeamPeople,
    deleteTeamPeople
} from '@/api/team'
import alertBox from '@/components/alert-box.vue'

export default defineComponent({
    name: 'Team',
    components: {
        'alert-box': alertBox
    },
    data () {
        return {
            searchValue: '',
            searchText: '',
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tableData: [] as TeamPeopleDto[],
            isAdd: false,
            isEdit: false as boolean | number,
            addPeopleData: {
                realName: '',
                mobile: ''
            }
        }
    },
    created () {
        this.handleCurrentChange()
    },
    methods: {
        async handleCurrentChange () {
            const result = await getTeamPeople({
                search: this.searchValue,
                page: this.pageNum,
                pageSize: this.pageSize
            })
            this.total = result.total
            this.tableData = result.data
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.handleCurrentChange()
        },
        cancelAddEdit () {
            this.isAdd = false
            this.isEdit = false
            this.addPeopleData.realName = ''
            this.addPeopleData.mobile = ''
        },
        edit (row: TeamPeopleDto) {
            this.addPeopleData.realName = row.real_name
            this.addPeopleData.mobile = row.mobile
            this.isEdit = row.client_user_id
        },
        async addPeople () {
            if (!this.addPeopleData.realName) {
                this.$message.error('请输入成员姓名')
                return
            }
            if (!this.addPeopleData.mobile) {
                this.$message.error('请输入成员手机号码')
                return
            }
            if (!/^1\d{10}$/.test(this.addPeopleData.mobile)) {
                this.$message.error('请输入正确的成员手机号码')
                return
            }
            if (typeof this.isEdit === 'number') {
                await editTeamPeople({
                    ...this.addPeopleData,
                    clientUserId: this.isEdit
                })
                this.$message.success('成员信息修改成功！')
            } else {
                await addTeamPeople(this.addPeopleData)
                this.$message.success('添加成员成功！')
            }
            this.addPeopleData.realName = ''
            this.addPeopleData.mobile = ''
            this.handleCurrentChange()
            this.isAdd = false
            this.isEdit = false
        },
        async deletePeople (row: TeamPeopleDto) {
            await this.$util.confirm('删除成员', '是否确认删除该成员？')
            await deleteTeamPeople({
                clientUserId: row.client_user_id
            })
            this.$message.success('成员删除成功！')
            this.handleCurrentChange()
        }
    }
})
